import * as React from "react"
import Layout from "../components/common/layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import HeroSection from "../components/seminare/seminarHero"

import ContactFooter from "../components/contact-footer"
import PartnerSlider from "../components/partner-slider"
import PresentationContainer from "../components/seminare/presentation-container"
import ImgContainer from "../components/seminare/imageContainer"
import SommelierSlider from "../components/seminare/sommelier-slider"
import GermanyMap from "../images/seminare/deutschland_karte.png"
// import SimpleMailForm from "../components/simple-mail-form"
import {
  BGLinearGradient,
  Separator,
  SeparatorColorFull,
} from "../components/common/styledComponentsHelper"
import {
  onlinePresentationCaptionText,
  bierTastingText,
  bookingText,
} from "../static/text/seminareContent"
import { customerVoices } from "./../static/text/commonContent"

export const Head = () => (
  <>
    <title>Seminare | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const Seminare = () => (
  <Layout>
    <HeroSection
      desktopImg="seminare_banner_desktop.png"
      mobileImg="seminare_banner_mobile.png"
      headlineText="Online und Präsenz Vorträge"
      responsiveHeadline="Online und Präsenz Vorträge"
      subheadlineText={
        <>
          Bierdeckel<span className="d-sm-none d-inline"> </span>kalkulation
          <br />
          Mit 3 Zahlen zu mehr Gewinn!
        </>
      }
      captionText=""
    />
    <BGLinearGradient>
      <Separator />
      <div className="container text-center">
        <h2 className="text-uppercase fw-bold text-light">
          Online und Präsenz<span className="text-success">Vorträge</span>
        </h2>
        <div className="text-light mt-4 fs-5 col-md-8 col-12 mx-auto">
          {onlinePresentationCaptionText}
        </div>
      </div>
      <Separator />
      <div className="container">
        <MapContainer className="row">
          <div className="col-lg-5 d-none d-lg-block"></div>
          <div className="col-lg-6 col-12">
            <PresentationContainer />
          </div>
        </MapContainer>
      </div>
      <Separator />
      <div className="bg-dark">
        <Separator />
        <div className="container">
          <h2 className="text-light text-uppercase text-bold text-center fw-bold">
            Teilnehmerstimmen <span className="text-success">(Auszug)</span>
          </h2>
          <br />
          {customerVoices}
        </div>
        <Separator />
      </div>
      <SeparatorColorFull />
      <div className="container text-center">
        <h2 className="text-uppercase fw-bold text-light">
          Unvergessliches Bier - Tasting{" "}
          <span className="text-success">Event</span>
        </h2>
        <div className="text-light mt-4 fs-5 col-md-8 col-12 mx-auto">
          {bierTastingText}
        </div>
      </div>
      <Separator />
      <div className="bg-dark">
        <ImgContainer />
      </div>
      <Separator />
      <SommelierSlider />
      <SeparatorColorFull />
      <div className="container">
        <div className="row flex-lg-row-reverse">
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <table className="h-100">
              <tbody>
                <tr>
                  <td className="pt-3 pb-3 align-middle">
                    <h2 className="text-uppercase text-light fw-bold">
                      Jetzt Online oder Präsenz -{" "}
                      <span className="text-success">Vortrag Buchen</span>
                    </h2>
                    <div className="text-light mt-4 fs-5">{bookingText}</div>
                    {/* <hr className="text-success" /> */}
                    {/* <div className="text-light fs-5 text-underline">
                      Geben Sie hier Ihre E-Mail ein um unsere{" "}
                      <span className="text-success fw-bold">Preisliste</span>{" "}
                      zu erhalten.
                    </div>
                    <SimpleMailForm request={"Preisliste"} /> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
            <StaticImage
              src="../images/seminare/vortrag_buchen.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Jetzt Vortrag buchen"
              placeholder="none"
            />
          </div>
        </div>
      </div>
      <Separator />
      <ContactFooter />
      <PartnerSlider />
    </BGLinearGradient>
  </Layout>
)

const MapContainer = styled.div`
  background-image: url(${GermanyMap});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 60vw;
  @media screen and (max-width: 992px) {
    height: auto;
    background-image: none;
  }
`

export default Seminare
